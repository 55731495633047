export const vehicleRegRegex =
  /(^[A-Za-z]{2} ?[0-9]{2} ?[A-Za-z]{3} ?$)|(^[A-Za-z][0-9]{1,3} ?[A-Za-z]{3} ?$)|(^[A-Za-z]{3} ?[0-9]{1,3} ?[A-Za-z] ?$)|(^[0-9]{1,4} ?[A-Za-z]{1,2} ?$)|(^[0-9]{1,3} ?[A-Za-z]{1,3} ?$)|(^[A-Za-z]{1,2} ?[0-9]{1,4} ?$)|(^[A-Za-z]{1,3} ?[0-9]{1,3} ?$)|(^[A-Za-z]{1,3}[0-9]{1,4}$)/;

// DEPRECATED - for better named variable
export const vehicleReg = vehicleRegRegex;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const mobileRegex = /((07[0-9]{9})|((\\+44|44)7[0-9]{9}))$/;

export const postcodeRegex = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

export const imeiRegex = /^\d{15,17}$/;

export const vinRegex = /\b[(A-H|J-N|P|R-Z|0-9)]{17}R?\b/;

export const policyReferenceRegex = /[a-z]+-[a-z-]+-[a-z-]+/;

/*
 * Matches alpha numeric strings not case sensitive
 * Example match - "Ra66Kcf"
 * Example mismatch - " kl; a&J0"
 */
export const alphaNumericAnyCaseRegex = /^[a-zA-Z0-9]+$/;

/**
 * Matches strings with at least one lowercase character
 */
export const containsLowercaseRegex = /^(?=.*?[a-z]).*$/;

/**
 * Matches strings with at least one uppercase character
 */
export const containsUppercaseRegex = /^(?=.*?[A-Z]).*$/;

/**
 * Matches strings with at least one digit
 */
export const containsDigitRegex = /^(?=.*?[0-9]).*$/;

/**
 * Matches strings that meet our password length requirement
 */
export const passwordLengthRequirementRegex = /^(?=.{6,}$).*$/;

/**
 * Matches strings that don't have a leading space
 */
export const noLeadingSpaceRegex = /^[^\s].*$/;

/**
 * Matches strings that don't have a trailing space
 */
export const noTrailingSpaceRegex = /^.*[^\s]$/;

export const isCognitoAllowedCharactersRegex = /^[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]*$/;

export const quoteTokenCleanerRegex = /[+]/g;

export const ukDriverLicenceNumberRegex = /^[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}$/;

/**
 * Matches numerical string, with optional decimal places from 0 - 2
 */
export const paymentRegex = /^\d+(?:\.\d{0,2})?$/;

